<template>
  <b-card>
    <h5>Cambiar contraseña</h5>
    <form-render :key="keyFormRenderPassword" :form.sync="form" :fields="fields" @send="updatePassword" ref="formRenderPassword" :buttonSend="buttonSend" containerButtonsClass="col-sm-12 mt-1 d-flex justify-content-end">
    <template #security>
      <h4 class="m-1 mt-2"><strong>Por favor introduzca su nueva contraseña con las siguientes características:</strong></h4>
      <ul>
          <li :class="statusPwd.length ? 'text-success' : ''">
              {{ $t('Longitud de al menos 10 caracteres') }} <i v-if="statusPwd.length" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
          <li :class="statusPwd.capitalLetter ? 'text-success' : ''">
              {{ $t('Debe contener al menos una letra mayúscula') }} <i v-if="statusPwd.capitalLetter" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
          <li :class="statusPwd.lowerCase ? 'text-success' : ''">
              {{ $t('Debe contener al menos una letra minúscula') }} <i v-if="statusPwd.lowerCase" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
          <li :class="statusPwd.number ? 'text-success' : ''">
              {{ $t('Debe contener al menos un número') }} <i v-if="statusPwd.number" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
          <li :class="statusPwd.specialCharacter ? 'text-success' : ''">
              {{ $t('Debe contener al menos un caracter especial (!@#$%^&*)') }} <i v-if="statusPwd.specialCharacter" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
          <li :class="statusPwd.samePasswords ? 'text-success' : ''">
              {{ $t('Las contraseñas deben ser idénticas en ambos campos') }} <i v-if="statusPwd.samePasswords" class="fa fa-check-circle" aria-hidden="true"></i>
          </li>
      </ul>
    </template>
    <template #buttons>
      <b-button variant="outline-light" class="ml-2" @click="resetForm"><feather-icon icon="RefreshCwIcon"/> {{ $t('Limpiar formulario') }}</b-button>  
    </template>
    </form-render>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoginService from '../login.service'

export default {
  components: {
  },
  data() {
    return {
      buttonSend: { color: 'warning', icon: 'SaveIcon', text: 'Actualizar' },
      form: {},
      fields: [],
      keyFormRenderPassword: 0,
      tokenRecaptcha: 'norendered',
      widget: null,
      statusPwd:{
        length: false,
        specialCharacter: false,
        capitalLetter: false,
        lowerCase: false,
        samePasswords: false,
        number: false
      },
      loginService: new LoginService(this)
    }
  },
  mounted () {
    this.fields = [
      { fieldType: 'FieldInput', type: 'password', label: 'Contraseña actual', name: 'current_password', range: true, containerClass: 'col-sm-12 col-md-12 container-info z-index-10', validation: 'required' },
      { name: 'security', useSlot: true, containerClass: 'col-sm-12'},
      { fieldType: 'FieldInput', type: 'password', label: 'Nueva contraseña', name: 'new_password', change: this.onChangeNewPass, containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required' },
      { fieldType: 'FieldInput', type: 'password', label: 'Confirma tu nueva contraseña', placeholder: 'Ingrese nueva contraseña', name: 'new_password2', change: this.onChangeNewPass, containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required' }
    ]
  },
  methods: {
    onChangeNewPass () {
      const lengthRegex = new RegExp('.{8,}')
      const specialCharacterRegex = new RegExp('[!@#$%^&*]')
      const capitalLetterRegex = new RegExp('[A-Z]')
      const lowerCaseregex = new RegExp('[a-z]')
      const numberRegex = new RegExp('[0-9]')

      this.statusPwd = {
        ...this.statusPwd,
        length: lengthRegex.test(!!this.form.new_password ? this.form.new_password.trim() : ''),
        specialCharacter: specialCharacterRegex.test(this.form.new_password),
        capitalLetter: capitalLetterRegex.test(this.form.new_password),
        lowerCase: !!this.form.new_password ? lowerCaseregex.test(this.form.new_password) : false,
        number: numberRegex.test(this.form.new_password),
        samePasswords: !!this.form.new_password && this.form.new_password2 === this.form.new_password
      }
    },
    resetForm () {
      this.form = {}
      Object.keys(this.statusPwd).forEach(key => {
        this.$set(this.statusPwd, key, false)
      })
      this.keyFormRenderPassword++
    },
    async updatePassword () {
      const user = this.$root.$data.auth.currentUser
      if (this.validateForm()) return 0
      this.setButtonSend('LoaderIcon')
      const credential = this.$root.$data.fb.auth.EmailAuthProvider.credential(this.$session.get('cas_user').email, this.form.current_password)
      user.reauthenticateWithCredential(credential)
        .then(() => {
          user.updatePassword(this.form.new_password)
            .then((resp) => {
              this.resetForm()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('¡Éxito!'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: this.$t('Su contraseña ha sido actualizada')
                }
              })
              this.resetForm()
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(end => {
              this.setButtonSend('SaveIcon')
            })
        })
        .catch((error) => {
          this.setButtonSend('SaveIcon')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast-change-password-error'),
              icon: 'WarningIcon',
              variant: 'danger',
              text: ['auth/too-many-requests'].includes(error.code) ? 'Has realizado demasiados intentos fallidos. Por favor, espera un momento antes de intentar nuevamente.' : 'Contraseña actual inválida'
            }
          })
          console.error('Error en la reautenticación: ', error)
        })
    },
    setButtonSend(type) {
      this.buttonSend.icon = type
      this.buttonSend.iconClass = ['SaveIcon'].includes(type) ?  '' : 'spinner'
      this.buttonSend.disabled = !['SaveIcon'].includes(type)
    },
    validateForm () {
      let message = ''
      if (!this.statusPwd.length || !this.statusPwd.specialCharacter || !this.statusPwd.capitalLetter || !this.statusPwd.lowerCase || !this.statusPwd.number || !this.statusPwd.samePasswords) {
        message = this.$t('La contraseña no cumple con todos los requisitos')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('toast-change-password-error'),
            icon: 'WarningIcon',
            variant: 'danger',
            text: message
          }
        })
      }
      return message !== ''
    }
  }
}
</script>
<style>
.z-index-10{
  z-index: 10;
}
</style>
